import React from "react"
import { graphql } from "gatsby"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Box } from "theme-ui"
import Layout from "../components/layout"
import LatestArticles from "../components/latestArticles"
import HomeSlider from "../components/homeSlider"
import NumbersCollection from "../components/blocks/numbersCollections"
import ImageAndText from "../components/blocks/imageAndText"
import Accordion from "../components/blocks/accordion"
import Video from "../components/blocks/video"
import FilterMetaTagDescription from "../utils/filterMetaTags"
import HeadquarterBlock from "../components/blocks/headquarterBlock"
import StoriesCustomCollection from "../components/blocks/storiesCustomCollection"

const Home = ({ data: { page, site, way, articles } }) => {
  // console.log(page)
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })
  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "home_slider" && (
            <HomeSlider slides={block.slides} way={way} />
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              title={block.title}
              image={block.image}
              numbers={block.numbers}
              link={block.link}
              addMarginBottom={block.addMarginBottom}
            />
          )}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              label={block.content.label}
              subtitle={block.content.subtitle}
              title={block.content.title}
              body={block.content.body}
              image={block.content.image}
              rightAligned={block.rightAligned}
              addMarginBottom={block.addMarginBottom}
            />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion
              title={block.title}
              subtitle={block.subtitle}
              image={block.image}
              items={block.items}
              link={block.link}
              alternate={block.alternate}
            />
          )}
          {block.model.apiKey === "video" && (
            <Video
              title={block.title}
              embedCode={block.embedCode}
              customHtml={block.customHtml}
              addMarginBottom={block.addMarginBottom}
            />
          )}
          {block.model.apiKey === "headquarter_block" && (
            <HeadquarterBlock
              body={block.body}
              locations={block.locations}
              images={block.images}
            />
          )}
          {block.model.apiKey === "stories_custom_collection" && (
            <StoriesCustomCollection stories={block.stories} />
          )}
        </Box>
      ))}
      {articles.nodes.length > 0 && (
        <LatestArticles articles={articles.nodes} />
      )}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    way: datoCmsTheWay(locale: { eq: $locale }) {
      id
      text
      callToAction
      externalCallToAction
      video
    }
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsHeadquarterBlock {
          id
          body
          locations {
            originalId
            name
            body
            coordinates {
              latitude
              longitude
            }
          }
          images {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsVideo {
          id
          title
          embedCode
          customHtml
          addMarginBottom
          model {
            apiKey
          }
        }
        ... on DatoCmsStoriesCustomCollection {
          id
          model {
            apiKey
          }
          stories {
            ...StoryDetails
            ...StoryAllSlugLocales
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          subtitle
          alternate
          image {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          link {
            id
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          rightAligned
          addMarginBottom
          model {
            apiKey
          }
        }
        ... on DatoCmsNumbersCollection {
          id
          title
          addMarginBottom
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { q: 100 }
            )
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
          link {
            id
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
        }
        ... on DatoCmsHomeSlider {
          id
          slides {
            locale
            id
            title
            subtitle
            image {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                forceBlurhash: false
              )
              mobile: gatsbyImageData(
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: { w: "640", h: "800", fit: "crop" }
              )
            }
            productImage {
              gatsbyImageData(
                height: 280
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
            link {
              ... on DatoCmsInternalLink {
                id
                anchor
                locale
                model {
                  apiKey
                }
                link {
                  ... on DatoCmsEventPage {
                    ...EventPageDetails
                  }  
                  ... on DatoCmsBlogPage {
                    ...BlogDetails
                  }
                  ... on DatoCmsPage {
                    ...PageDetails
                    ...PageTreeParent
                    ...AllSlugLocales
                  }
                  ... on DatoCmsArticle {
                    ...ArticleDetails
                    ...ArticleAllSlugLocales
                  }
                  ... on DatoCmsArticleCategory {
                    ...ArticleCategoryDetails
                    ...ArticleCategoryAllSlugLocales
                  }
                }
              }
            }
          }
          model {
            apiKey
          }
        }
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 12
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
